<template>
  <v-sheet class="d-flex align-center" style="height: 70px">
    <v-img
      :src="club.small_logo_url"
      max-height="50"
      max-width="50"
      contain
    ></v-img>
    <v-sheet>
      <v-sheet
        v-if="!editing_name"
        @dblclick="editing_name = true" 
        class="ml-4 font-weight-bold"
      >
        {{ club.club_name }}
      </v-sheet>
      <v-text-field
        class="ml-4"
        :value="club.club_name"
        @input="new_name = $event"
        hide-details
        v-else
        append-icon="mdi-check"
        @click:append="changeName"
      ></v-text-field>
      <v-sheet class="ml-4 text--secondary">
        {{ club.club_name_short }}
      </v-sheet>
    </v-sheet>
    <v-spacer></v-spacer>
    <v-btn
      fab
      text
      small
    >
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['club'],
  data: () => ({
    editing_name: false,
    new_name: '',
    editing_short_name: true
  }),
  methods: {
    ...mapActions('admin', [
      'updateClub'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    changeName() {
      if(!this.new_name) {
        this.editing_name = false
        return
      }

      this.updateClub({ id: this.club.id, club_name: this.new_name })
        .then(() => {
          this.success('club updated')
          this.editing_name = false
        })
        .catch(e => {
          this.error(e)
        })
    }
  }
}
</script>