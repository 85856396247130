<template>
  <v-sheet class="pa-4">
    <v-sheet style="border-left: 3px solid lightgrey; color: grey;" class="pl-4">
      League clubs are the clubs that play in the league and are used as teams in the games
    </v-sheet>
    <v-sheet class="d-flex justify-end">
      <v-btn outlined small @click="adding_team = true">
        <v-icon>mdi-plus</v-icon>
        Add club
      </v-btn>
    </v-sheet>
    <v-sheet class="d-flex justify-center" v-if="loading_clubs">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-sheet>
    <v-sheet v-else>
      <club-card
        v-for="club in clubs"
        :club="club"
        :key="club.id"
      ></club-card>
    </v-sheet>
    <v-dialog v-model="adding_team" max-width="800">
      <v-card>
        <v-card-title>
          Add club to league
        </v-card-title>
        <v-sheet class="px-6">
          <v-text-field v-model="new_club_name" label="Club name" />
          <v-text-field v-model="new_club_short_name" label="Club short name" />
          <v-file-input
            label="Club logo (png)"
            accept="image/png"
            prepend-icon="mdi-camera"
            @change="onFileChange"
            :prepend-inner-icon="wasUploaded ? 'mdi-check' : ''"
          >
          </v-file-input>
          <v-img
            v-if="downsizedImage"
            :src="downsizedImage"
            alt="Downsized Image"
            :width="downsized_image_width"
            :height="downsized_image_height"
          ></v-img>
          <v-expand-transition>
            <v-progress-linear
              class="mt-2"
              height="20"
              striped
              rounded
              v-if="file"
              :value="file ? uploadProgress(file.name) : 0"
              :color="wasUploaded ? 'green' : 'blue'"
            >
              Original file {{ wasUploaded ? ' done' : '' }}
            </v-progress-linear>
          </v-expand-transition>
          <v-expand-transition>
            <v-progress-linear
              class="mt-2"
              height="20"
              striped
              rounded
              v-if="small_file"
              :value="small_file ? uploadProgress(small_file.name) : 0"
              :color="wasUploadedSmall ? 'green' : 'blue'"
            >
              Small file {{ wasUploadedSmall ? ' done' : '' }}
            </v-progress-linear>
          </v-expand-transition>
          <v-text-field
            label="Logo url"
            disabled
            v-model="logo_url"
          ></v-text-field>
          <v-text-field
            label="Small logo url"
            disabled
            v-model="small_logo_url"
          ></v-text-field>
        </v-sheet>
        <v-card-actions>
          <v-btn @click="adding_team = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading_clubs" :disabled="!canSave" color="primary" @click="createClub">
            <v-icon>mdi-plus</v-icon>
            Create club
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ClubCard from '@/components/tiimi_admin/league/club/ClubCard.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  created() {
    this.initClubs(this.$route.params.league_id)
  },
  components: { ClubCard },
  data: () => ({
    adding_team: false,
    new_club_name: '',
    new_club_short_name: '',
    file: null,
    small_file: null,
    logo_url: null,
    small_logo_url: null,
    downsizedImage: null,
    downsized_image_width: 0,
    downsized_image_height: 0,
    saving: false
  }),
  computed: {
    ...mapGetters('file', [
      'uploadProgress',
      'uploadError',
      'filesUploaded'
    ]),
    ...mapGetters('admin', [
      'clubs',
      'loading_clubs'
    ]),
    wasUploaded() {
      if(!this.file) return false
      return this.filesUploaded.includes( this.file.name )
    },
    wasUploadedSmall() {
      if(!this.small_file) return false
      return this.filesUploaded.includes( this.small_file.name )
    },
    canSave() {
      return this.wasUploaded 
      && this.wasUploadedSmall 
      && this.adding_team 
      && this.new_club_name 
      && this.new_club_short_name
    }
  },
  methods: {
    ...mapActions('file', [
      'uploadFile'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    ...mapActions('admin', [
      'addClub',
      'initClubs'
    ]),
    clear() {
      this.file = null
      this.small_file = null
      this.logo_url = null
      this.downsizedImage = null
    },
    async onFileChange(file) {
      if(!file || file.type !== 'image/png') {
        this.clear()
        return
      }

      file = new File([file], uuidv4(), { type: file.type });
      this.file = file

      this.uploadFile({ file: file, folder: "clubLogos" })
        .then(res => {
          this.logo_url = res.fileUrl
        })

      const downSizedImageUrl = await this.downsizeImage(file, 200, 200);
      const downsizedFile = await this.dataURLToFile(downSizedImageUrl, uuidv4())
      this.small_file = downsizedFile

      this.uploadFile({ file: downsizedFile, folder: "clubLogos" })
        .then(res => {
          this.small_logo_url = res.fileUrl
        })
    },
    async downsizeImage(file, maxWidth, maxHeight) {
      return new Promise((resolve) => {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
  
          img.onload = () => {
            // Get original image dimensions
            const originalWidth = img.width;
            const originalHeight = img.height;
  
            // Calculate aspect ratio
            const aspectRatio = originalWidth / originalHeight;
  
            // Calculate new dimensions while preserving aspect ratio
            let newWidth = originalWidth;
            let newHeight = originalHeight;
  
            if (originalWidth > maxWidth || originalHeight > maxHeight) {
              if (originalWidth > originalHeight) {
                newWidth = maxWidth;
                newHeight = Math.floor(maxWidth / aspectRatio);
              } else {
                newHeight = maxHeight;
                newWidth = Math.floor(maxHeight * aspectRatio);
              }
            }
  
            this.downsized_image_width = newWidth
            this.downsized_image_height = newHeight
  
            // Create a canvas element to resize the image
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
  
            // Set the canvas dimensions to the new downsized dimensions
            canvas.width = newWidth;
            canvas.height = newHeight;
  
            // Draw the image onto the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            // Convert the canvas to a data URL (resized PNG image)
            this.downsizedImage = canvas.toDataURL('image/png');
            
            resolve(this.downsizedImage)
          };
        };
  
        // Read the image file as a data URL
        reader.readAsDataURL(file);
      })
    },
    dataURLToFile(dataURL, fileName) {
      return fetch(dataURL)
        .then(res => res.blob())
        .then(blob => {
          // Create a new File object using the Blob and a specified filename
          const file = new File([blob], fileName, { type: blob.type });
          return file;
        });
    },
    createClub() {
      const { league_id } = this.$route.params
      this.saving = true
      this.addClub({
        league_id,
        logo_url: this.logo_url,
        small_logo_url: this.small_logo_url,
        club_name: this.new_club_name, 
        club_name_short: this.new_club_short_name
      })
      .then(() => {
        this.success("Club created")
        this.adding_team = false
        this.clear()
      })
      .catch(e => {
        this.error(e)
      })
      .finally(() => {
        this.saving = false
      })
    }
  }
}
</script>